import React from "react";
import FloatingLogoAndButton from "../FloatingLogoAndButton/FloatingLogoAndButton";

function AdminLogin() {
  return (
    <>
      <FloatingLogoAndButton />
      <div className="bg-black flex justify-center items-center py-4 h-screen">
        <div className="flex flex-col items-center justify-center border-[1px] border-red-600 p-4 gap-4">
          <input
            type="text"
            placeholder="Login"
            name="login"
            className="py-2 px-4 rounded-full bg-transparent text-red-600 border-[1px] border-red-600  hover:border-white"
          />
          <input
            type="password"
            placeholder="Hasło"
            name="password"
            className="py-2 px-4 rounded-full bg-transparent text-red-600 border-[1px] border-red-600  hover:border-white"
          />
          <button className="bg-red-600 text-white py-2 px-6 rounded-full">
            Zaloguj
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
