import React from "react";
import videoBg from "../../assets/videos/Whitebull-Demo-23rf.mp4";
import hero0 from "../../assets/images/webp/hero0-webp.webp";
import hero1 from "../../assets/images/webp/hero1-webp.webp";
import hero3 from "../../assets/images/webp/hero3-webp.webp";
import hero4 from "../../assets/images/webp/hero4-webp.webp";
import hero5 from "../../assets/images/webp/hero5-webp.webp";
import hero6 from "../../assets/images/webp/hero6-webp.webp";

export default function Hero() {
  return (
    <div className="h-full md:h-screen w-full flex justify-center items-center pb-2 px-2 pt-[116px]">
      <div className="h-full w-full flex flex-col md:flex-row justify-center gap-2">
        <div className="w-full md:w-1/3 h-full flex flex-col">
          <img
            src={hero0}
            alt="whitebull-people"
            className="w-full h-1/3 object-cover"
          />

          <img
            src={hero3}
            alt="whitebull-people"
            className="w-full h-1/3 object-cover pt-2"
          />
          <img
            src={hero4}
            alt="whitebull-people"
            className="w-full h-1/3 object-cover object-bottom pt-2"
          />
        </div>
        <video
          src={videoBg}
          autoPlay
          loop
          muted
          className="w-full md:w-1/3 h-full object-cover hidden md:block"
        />
        <div className="w-full md:w-1/3 flex flex-col">
          <img
            src={hero1}
            alt="whitebull-people"
            className="w-full h-1/3 object-cover"
          />
          <img
            src={hero6}
            alt="whitebull-people"
            className="w-full h-1/3 object-cover object-bottom pt-2"
          />
          <img
            src={hero5}
            alt="whitebull-people"
            className="w-full h-1/3 object-cover pt-2"
          />
        </div>
      </div>
    </div>
  );
}
