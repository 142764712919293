import React from "react";
import mainLogo from "../../assets/images/whitebull.png";
import { Link } from "react-router-dom";
import ButtonRed from "../ButtonRed/ButtonRed";

export default function FloatingLogoAndButton({ resMenuOpener }) {
  return (
    <div className="fixed top-0 left-0 z-30 w-screen md:w-full flex justify-between items-center px-2 md:px-4">
      <div>
        <Link to="/">
          <img
            src={mainLogo}
            className={"max-w-28 md:max-w-40"}
            alt="WhiteBull Logo"
          />
        </Link>
      </div>
      <div>
        <ButtonRed
          text={"Rezerwuj"}
          type={"reservationChoose"}
          url={"https://zjedz.my/whitebull-steakhouse"}
          outsideFunc={resMenuOpener}
        />
      </div>
    </div>
  );
}
